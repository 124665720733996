<template>
    <div class="f1 apps-page w-full dF fC f1 px-4">
        <div class="dF aC">
            <div class="dF aC f1">
                <h3>Partner Deals</h3>
            </div>
        </div>
        <a-card :bodyStyle="{ padding: 0 }" class="my-3">
            <div>
                <a-table
                    :rowKey="(e) => e.key"
                    :columns="columns"
                    :dataSource="partnerDeals"
                    :loading="loading"
                    :pagination="{
                        current: currentPage,
                        total: totalPartners,
                        pageSize: pageSize,
                    }"
                    @change="handleChange"
                >
                    <div slot="company" slot-scope="partnerDeal">
                        {{ partnerDeal.company }}
                    </div>
                    <div slot="companyAddress" slot-scope="partnerDeal">
                        {{ partnerDeal.companyAddress }}
                    </div>
                    <div slot="companyWebsite" slot-scope="partnerDeal">
                        {{ partnerDeal.companyWebsite }}
                    </div>
                    <div slot="registrationExpiry" slot-scope="partnerDeal">
                        {{ calculateExpiry(partnerDeal.registrationExpiry) }}
                    </div>
					<div slot="partner" slot-scope="partnerDeal">
                        {{ partnerDeal.partner.companyName }}
                    </div>
					<div
                        slot="user"
                        slot-scope="partnerDeal"
                        class="dF aC"
                        style="justify-content: center"
                    >
						<a-tooltip overlayClassName="change-tooltip-color">
							<template slot="title">
								<div class="dF aC" style="gap: 10px">
									<div>
										{{ partnerDeal.user.firstName }}
										{{ partnerDeal.user.lastName }}
									</div>
									<div>
										({{ partnerDeal.user.email }})
									</div>
								</div>
							</template>
							<a-avatar
								shape="circle"
								size="default"
								:class="$style.avatar"
								:key="partnerDeal.user.id"
								:style="{
									'background-color':
										partnerDeal.user.id === 'id'
											? '#9693e7'
											: 'var(--primary)',
								}"
								>{{
									partnerDeal.user.firstName
										? partnerDeal.user.id !== "id"
											? partnerDeal.user.firstName[0].toUpperCase()
											: partnerDeal.user.firstName
										: "--"
								}}</a-avatar
							>
						</a-tooltip>
                    </div>
                    <div
                        slot="createdAt"
                        slot-scope="partnerDeal"
                        style="color: var(--med-gray)"
                    >
                        {{ getDate(partnerDeal.createdAt) }}
                    </div>
                    <div
                        slot="actions"
                        slot-scope="partnerDeal"
                        class="dF aC"
                        style="gap: 20px"
                    >
                        <div
                            @click="selectedPartnerDeal = partnerDeal;deleteModal = true;"
                            class="popoverContent"
                        >
							<a-icon type="delete" style="color:red" />
                        </div>
                    </div>
                </a-table>
            </div>
        </a-card>
        <a-modal
            v-model="deleteModal"
            title="Delete Deal"
            ok-text="Delete"
            @ok="deletePartnerDeal"
            :centered="true"
        >
            <p>
                Are you sure you want to delete this Deal
                <strong>
                    {{ selectedPartnerDeal.company }}
                </strong>
                ?
            </p>
        </a-modal>
    </div>
</template>

<script>
import { mapMutations } from "vuex";
import moment from "moment";
import _ from "lodash";

export default {
    data() {
        return {
            sortedInfo: {
                key: "createdAt",
                order: "DESC",
            },
            partnerDeals: [],
            loading: false,
            deleteModal: false,
            confirmLoading: false,
            currentPage: 1,
            totalPartners: 0,
            pageSize: 10,
            selectedPartnerDeal: {},
        };
    },
    computed: {
        search() {
            return this.$store.state.searchFilter.toLowerCase();
        },

        columns() {
            return [
                {
                    title: "Company Name",
                    key: "company",
                    scopedSlots: { customRender: "company" },
                    sorter: true,
                },
                {
                    title: "Company Address",
                    key: "companyAddress",
                    scopedSlots: { customRender: "companyAddress" },
                    sorter: true,
                },
                {
                    title: "Company Website",
                    key: "companyWebsite",
                    scopedSlots: { customRender: "companyWebsite" },
                    sorter: true,
                },
                {
                    title: "Registration Expiry",
                    key: "registrationExpiry",
                    scopedSlots: { customRender: "registrationExpiry" },
                    sorter: true,
                },
                {
                    title: "Partner",
                    key: "partner",
                    scopedSlots: { customRender: "partner" },
                    sorter: true,
                },
                {
                    title: "User",
                    key: "user",
                    scopedSlots: { customRender: "user" },
                    sorter: true,
                },
				{
					title: "Created At",
					key: "createdAt",
					scopedSlots: { customRender: "createdAt" },
					sorter: true,
				},
				{
					title: "Actions",
					key: "actions",
					scopedSlots: { customRender: "actions" },
				}
            ];
        },
    },
    watch: {
        search() {
            this.searchDebounce();
        },
    },
    created() {
        this.getPartnersCount();
        this.getPartnerDeals();
        // this.$store.dispatch('FETCH_ALL_USERS')
    },

    methods: {
        ...mapMutations([
            "UPDATE_SEARCH"
        ]),

        searchDebounce: _.debounce(function () {
            this.getPartnersCount();
            this.getPartnerDeals();
        }, 1000),

		calculateExpiry(milliseconds){
			const expiry = moment(milliseconds);
			const now = moment();
			const days = expiry.diff(now, 'days');
			if (days > 0) {
			return `${days} days left`;
			} else {
			const hours = expiry.diff(now, 'hours');
			if (hours > 0) {
				return `${hours} hours left`;
			} else {
				return 'Expired';
			}
			}
		},

        async getPartnersCount() {
            try {
                let searchQuery = "";
                if (this.search) {
                    searchQuery = `?companyName=${this.search}`;
                }
                let { data } = await this.$api.get(
                    `/partner-deals/count${searchQuery}`
                );
                this.totalPartners = data;
            } catch (err) {
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err, "Error while fetching partner deals count. Please try again."))
				}
            }
        },

        async getPartnerDeals() {
            this.loading = true;
            try {
                let start = (this.currentPage - 1) * this.pageSize;
                let searchQuery = "";
                if (this.search) {
                    searchQuery = `&code=${this.search}`;
                }
                let { data } = await this.$api.get(
                    `/partner-deals?_start=${start}&_limit=${this.pageSize}&_sort=${this.sortedInfo.key}:${this.sortedInfo.order}${searchQuery}`
                );
                this.partnerDeals = data.map((u, index) => {
                    return {
                        ...u,
                        key: index,
                    };
                });
            } catch (err) {
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err, "Error while fetching partner deal list. Please try again."))
				}
            }
            this.loading = false;
        },

        async deletePartnerDeal() {
            try {
                await this.$api.delete(`/partner-deals/${this.selectedPartnerDeal.id}`);
                this.deleteModal = false;
                this.selectedPartnerDeal = {};
                this.getPartnersCount();
                this.getPartnerDeals();
            } catch (err) {
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err, "Error while deleting partner deal. Please try again."))
				}
            }
        },

        validateEmail(rule, value, callback) {
            if (value === "") {
                callback(new Error("Please enter an email"));
            } else {
                const result =
                    /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(
                        value
                    );
                if (result) {
                    callback();
                } else {
                    callback(new Error("That's not a valid email address."));
                }
            }
        },

        getDate(date) {
            return moment(date).format("D/M/YYYY");
        },

        handleChange(pagination, filter, sorter) {
            if (pagination) {
                this.currentPage = pagination.current;
            }
            if (sorter && sorter.column) {
                this.sortedInfo = {
                    key: sorter.columnKey,
                    order: sorter.order === "descend" ? "DESC" : "ASC",
                };
				this.getPartnerDeals();
            }
        },
    },

    beforeDestroy() {
        this.UPDATE_SEARCH("");
    },
};
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>

<style lang="scss" scoped>
.button {
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
    padding: 4px 10px;
}

.popoverContent {
    height: max-content;
    line-height: 30px;
    padding: 0 10px;
}
.popoverContent:hover {
    background-color: var(--off-white-light);
    color: var(--primary);
    cursor: pointer;
}
</style>

<style lang="scss">
.popoverStyle .ant-popover-inner-content {
    padding: 5px;
    background-color: white;
    border-radius: 20px;
}
</style>
